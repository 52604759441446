import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login/login.vue')
      },
      {
        path: 'login/:type/',
        name: 'Login',
        component: () => import('@/views/Login/login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Login/Register.vue'),
        meta: {
          title: '注册'
        }
      }
      // {
      //   path: '/forget',
      //   name: 'forget',
      //   component: () => import('../views/Login/forget.vue')
      // }
    ]
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/agreement.vue'),
    meta: {
      title: '用户注册协议'
    }
  },
  {
    path: '/agreementEn',
    name: 'agreementEn',
    component: () => import('@/views/agreementEn.vue'),
    meta: {
      title: '用户注册协议'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/privacyEn',
    name: 'privacyEn',
    component: () => import('@/views/privacyEn.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/partners.vue'),
    meta: {
      title: '合作方隐私政策'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
