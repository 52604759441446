<template>
  <div class="wrapper">
    <div class="container">
      <!--页头-->
      <div class="page-header" style="line-height: 40px">
        <div class="ph-left">
          <!--页面标题-->
          <h3 style="text-align:center">User Registration Agreement</h3>
        </div>
      </div>
      <!--内容-->
      <div class="agreement-content">
        <ul>
          <li>
            <P>
              Dear User, welcome to register as a member of Printer Cybertech (hereinafter referred to as "the Site" or "this Site").
            </P>

            <p>
              Printer Cybertech hereby specially reminds users to carefully read and fully understand all the rights and limitations stipulated in this "User Registration Agreement" (hereinafter referred to as "the Agreement"). Please read carefully and choose to accept or not accept this "Agreement" (minors should read it accompanied by their legal guardians). Unless you accept all the terms of this "Agreement", you have no right to register as a member or use and its related services.
            </p>
          </li>

          <li>
            <h3>1.General Provisions</h3>
          </li>

          <li>
            <p>
              1.1 Before registering, users should carefully read this agreement and agree to comply with this agreement before becoming a registered user. Whether or not you have carefully read the "Agreement" before registration, as long as you click the "Register" button at the bottom of the registration page and successfully register as a member according to the website registration procedure, an agreement relationship is automatically formed between the user and Printer Cybertech, and the user shall be bound by this agreement. When using special services or products, users should agree to accept the relevant agreements before using them.
            </p>

            <p>
              1.2 This Agreement may be updated by Printer Cybertech at any time, and users should pay attention in time. This site does not assume the obligation to notify. The notices, announcements, statements, or other similar content of this site are part of this Agreement.
            </p>
          </li>

          <li>
            <h3>2.Service Content</h3>
          </li>

          <li>
            <p>
              2.1 The specific content of Printer Cybertech is provided by this site according to actual conditions.
            </p>

            <p>
              2.2 This site only provides related network services. In addition, the equipment related to network services (such as personal computers, mobile phones, and other devices related to accessing the internet or mobile networks) and the required fees (such as telephone fees and internet access fees for accessing the internet, and mobile fees for using mobile networks) shall be borne by the users themselves.
            </p>
          </li>

          <li>
            <h3>3.User Account</h3>
          </li>

          <li>
            <p>
              3.1 Users who have completed the registration procedure through the website's registration system and passed identity authentication become official users and can obtain all the rights that the site stipulates users should enjoy; those who have not been authenticated only enjoy part of the member rights stipulated by the site. Printer Cybertech reserves the right to change the rights of members.
            </p>

            <p>
              3.2 Users are obliged to ensure the security of passwords and accounts. Users shall bear full responsibility for any loss or damage caused by all activities conducted using this password and account, and this site does not assume any responsibility. If a user finds unauthorized use of the account or any other security problems occur, he/she should immediately change the account password and keep it properly. If necessary, please notify the site. The site does not assume any responsibility for illegal use of accounts due to hacker behavior or user negligence.
            </p>
          </li>

          <li>
            <h3>4.Rules of Use</h3>
          </li>

          <li>
            <p>
              4.1 Comply with the relevant laws and regulations of the People's Republic of China, including but not limited to the "The Regulations of the People's Republic of China for Safety Protection of Computer Information Systems", "Regulations on the Protection of Computer Software", "Interpretation of the Supreme People's Court on the Application of Laws in the Trial of Computer Network Copyright Disputes", "Decision of the Standing Committee of the National People's Congress on Preserving Computer Network Security", "Internet Electronic Bulletin Service Management Regulations", "Internet News Information Service Management Regulations", "Measures for the Administrative Protection of Internet Copyright" and "Regulations on Protection of the Information Transmission Rights on Internet", and other laws and regulations on computer internet and intellectual property, and implementation measures.
            </p>

            <p>
              4.2 Users are fully responsible for the content they publish, upload, or transmit. All users shall not publish, reprint, or transmit information containing any of the following contents on any page of this site, otherwise, this site has the right to handle it by itself without notifying the user:
            </p>

            <p>
              (1) Violating the basic principles established by the Constitution;
            </p>

            <p>
              (2) Endangering national security, leaking state secrets, subverting state power, and undermining national unity;
            </p>

            <p>
              (3) Harming national honor and interests;
            </p>

            <p>
              (4) Inciting national hatred and discrimination, undermining national unity;
            </p>

            <p>
              (5) Undermining the state's religious policies, promoting cults and feudal superstitions;
            </p>

            <p>
              (6) Spreading rumors, disturbing social order, and undermining social stability;
            </p>

            <p>
              (7) Spreading obscenity, pornography, gambling, violence, terror, or instigating crimes;
            </p>

            <p>
              (8) Insulting or slandering others, infringing on others' legitimate rights and interests;
            </p>

            <p>
              (9) Inciting illegal assembly, association, procession, demonstration, and gathering to disturb social order;
            </p>

            <p>
              (10) Engaging in activities in the name of illegal civil organizations;
            </p>

            <p>
              (11) Containing content prohibited by other laws and administrative regulations.
            </p>

            <p>
              4.3 Users promise to have full intellectual property rights or have obtained legal authorization from relevant rights holders for all information published or uploaded to this site (i.e., works as stipulated in the "The Copyright Law of the People's Republic of China", including but not limited to texts, pictures, music, movies, performances, recordings, and video products, and computer programs, etc.); if users violate this provision and cause third-party claims against the site, users should fully compensate the site for all expenses (including but not limited to various compensation fees, litigation agency fees, and other reasonable expenses incurred for this purpose);
            </p>

            <p>
              4.4 When a third party believes that the user-published or uploaded information to this site infringes on their rights and sends a rights notice according to the "Regulations on Protection of the Information Transmission Rights on Internet" or relevant legal provisions to this site, the user agrees that this site can independently judge and decide to delete the allegedly infringing information unless the user submits written evidence to exclude the possibility of infringement, this site will not automatically restore the deleted information;
            </p>

            <p>
              (1) Not to use network services for any illegal purposes;
            </p>

            <p>
              (2) Comply with all network agreements, regulations, and procedures related to network services;
            </p>

            <p>
              (3) Do not use this site for any behavior that may adversely affect the normal operation of the internet;
            </p>

            <p>
              (4) Do not use this site for any behavior detrimental to this site.
            </p>

            <p>
              4.5 If users violate any of the above provisions when using network services, this site has the right to require users to correct or directly take all necessary measures (including but not limited to deleting the content posted by users, suspending or terminating the user's right to use network services) to mitigate the impact of improper user behavior.
            </p>
          </li>

          <li>
            <h3>5.Privacy Protection</h3>
          </li>

          <li>
            <p>
              5.1 This site does not publicly disclose or provide individual user registration information and the non-public content stored in this site when using network services, except in the following cases:
            </p>

            <P>
              (1) Not to use network services for any illegal purposes;
            </P>

            <P>
              (2) With the user's explicit authorization in advance; according to the requirements of relevant laws and regulations;
            </P>

            <P>
              (3) According to the requirements of relevant government authorities;
            </P>

            <P>
              (4) To safeguard the public interest.
            </P>

            <P>
              5.2 This site may cooperate with third parties to provide relevant network services to users, in which case, if the third party agrees to bear the same responsibility as this site to protect user privacy, this site has the right to provide the user's registration information to the third party.
            </P>

            <P>
              5.3 Under the premise of not disclosing individual user privacy information, this site has the right to analyze the entire user database and make commercial use of the user database.
            </P>
          </li>

          <li>
            <h3>6.Copyright Statement</h3>
          </li>

          <li>
            <p>
              6.1 The copyrights of texts, pictures, audio, video, etc., on this site are owned by Printer Cybertech or jointly owned with the authors, and shall not be reprinted without the permission of this site.
            </p>

            <p>
              6.2 The unique identifiers, layout designs, compilation methods, etc., that belong to this site are owned by Printer Cybertech, and shall not be copied or reprinted without permission from this site.
            </p>

            <p>
              6.3 All contents of this site represent the author's own position and viewpoint only, and have nothing to do with this site. The author himself/herself shall bear all legal responsibilities.
            </p>

            <p>
              6.4 This site reserves the right to take legal action against malicious reproduction of this site's content.
            </p>
          </li>

          <li>
            <h3>7.Responsibility Statement</h3>
          </li>

          <li>
            <p>
              7.1 Users clearly agree that the risks and all consequences of using the site's network services shall be entirely borne by the users themselves, and Printer Cybertech assumes no responsibility for this.
            </p>

            <p>
              7.2 This site cannot guarantee that network services will meet user requirements, and cannot guarantee the timeliness, security, and accuracy of network services.
            </p>

            <p>
              7.3 This site does not guarantee the accuracy and completeness of external links that are set for user convenience, nor does it assume any responsibility for the content of any web page that is not actually controlled by this site referred to by such external links.
            </p>

            <p>
              7.4 For network service interruptions or other defects caused by force majeure or reasons beyond the control of this site, this site assumes no responsibility but will try to reduce the losses and impacts caused to users.
            </p>

            <p>
              7.5 For the following products or services provided to users by this site that have quality defects in themselves and any losses caused thereby, this site does not need to assume any responsibility:
            </p>

            <p>
              (1) All kinds of free network services provided to users by this site;
            </p>

            <p>
              (2) Any products or services given to users by this site.
            </p>

            <p>
              7.6 This site reserves the right to modify or terminate this service (or any part thereof) temporarily or permanently at any time and does not assume any responsibility to users or any third party, regardless of notification or not.
            </p>
          </li>

          <li>
            <h3>8.Supplementary Provisions</h3>
          </li>

          <li>
            <p>
              8.1 The conclusion, execution, interpretation, and dispute resolution of this agreement shall be applicable to the laws of the People's Republic of China.
            </p>

            <p>
              8.2 If any clause in this agreement is invalid or unenforceable for any reason, the other clauses of this agreement shall still be valid and binding.
            </p>

            <p>
              8.3 The right to interpret and revise this agreement belongs to Printer Cybertech.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div><!--/wrapper-->
</template>

<script>
export default {
  name: 'AgreementPage',
};
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  .container {
    width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
  }
  .agreement-content {
    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 20px;
      }
    }
    h3, h4, p {
      margin: 0 0 10px;
    }
  }
  @media screen and (max-width: 500px) {
    .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>
