/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const validMap = ['admin', 'editor'];
  return validMap.indexOf(str.trim()) >= 0;
}

/* 手机号 11位 */
export function validateMobile(textval) {
  const urlregex = /^[1]([3-9])[0-9]{9}$/;
  return urlregex.test(textval);
}

export function isUsername(val) {
  const regex = /^[\u4E00-\u9FA5\da-zA-Z_]{2,16}$/;
  return regex.test(val);
}

/* 是否是邮箱 */
export function isEmail(str) {
  const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  return reg.test(str);
}

/* 判断公司名 */
export function checkTeamName(val) {
  const reg = /^.{2,50}$/;
  return reg.test(val);
}

/* 判断详细地址 */
export function checkDetailAddress(val) {
  const reg = /^.{0,50}$/;
  return reg.test(val);
}

/* 判断企业介绍 */
export function checkTeamIntro(val) {
  const reg = /^.{0,500}$/;
  return reg.test(val);
}

/* 判断故障类型 */
export function checkRepairType(val) {
  if (val === '' || val.length > 20) {
    return false;
  }
  return true;

  // const reg = /^.{0,20}$/
  // return reg.test(val)
}

/* 判断故障描述 */
export function checkRepairDesc(val) {
  const reg = /^.{0,100}$/;
  return reg.test(val);
}

/* 判断维修方式 */
export function checkRepairStyle(val) {
  const reg = /^.{0,20}$/;
  return reg.test(val);
}

/* 判断部位 */
export function checkPart(val) {
  const reg = /^.{2,50}$/;
  return reg.test(val);
}

/* 判断组件 */
export function checkElement(val) {
  const reg = /^.{2,50}$/;
  return reg.test(val);
}

/* 判断工作 */
export function checkWork(val) {
  const reg = /^.{2,50}$/;
  return reg.test(val);
}

/* 判断SOP */
export function checkSop(val) {
  const reg = /^.{2,200}$/;
  return reg.test(val);
}

/* 判断仪器 */
export function checkTool(val) {
  const reg = /^.{0,50}$/;
  return reg.test(val);
}

/* 判断标准 */
export function checkStandard(val) {
  const reg = /^.{2,200}$/;
  return reg.test(val);
}

/* 判断保养单名称 */
export function checkMaintainName(val) {
  const reg = /^.{2,50}$/;
  return reg.test(val);
}

/* 非空，不超过50字 */
export function notNullAndMoreThan10C(val) {
  const reg = /^.{1,10}$/;
  return reg.test(val);
}

/* 非空，不超过50字 */
export function notNullAndMoreThan20C(val) {
  const reg = /^.{1,20}$/;
  return reg.test(val);
}

/* 非空，不超过50字 */
export function notNullAndMoreThan50C(val) {
  const reg = /^.{1,50}$/;
  return reg.test(val);
}

/* 非空，不超过100字 */
export function notNullAndMoreThan100C(val) {
  const reg = /^.{1,100}$/;
  return reg.test(val);
}

/* 非空，不超过length字 */
export function notNullAndMoreThan(val, length) {
  const ddd = '^.{1,';
  const ddd2 = '}$';
  const reg = new RegExp(ddd + length + ddd2);
  return reg.test(val);
}

export function spaceAndEnterChars200(val) {
  const reg = /^[\w\S\s]{1,200}$/;
  return reg.test(val);
}

/* 正整数、零、正浮点数 */
export function isZeroPIntOrPDouble(val) {
  const regInt = /^\d+/;
  const regDouble = /^\d+(\\.\d+)?/;
  return regInt.test(val) || regDouble.test(val);
}

// 验证监控设备名称
export function checkMonitorName(val) {
  const reg = /[a-zA-Z0-9_\u4e00-\u9fa5-]{2,20}/;
  return reg.test(val);
}

function isIDCard(val) {
  const regex = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  return regex.test(val);
}

export function isUserTag(val) {
  const regex = /^[\u4E00-\u9FA5\da-zA-Z_]{1,50}$/;
  return regex.test(val);
}

function isPassword(val) {
  const regex = /^[\w!@#$%^&*]{6,16}$/;
  return regex.test(val);
}

export function checkUserName(rule, value, callback) {
  if (!value || value.trim() === '') {
    callback(new Error('请输入姓名'));
  } else if (!isUsername(value)) {
    callback(new Error('请输入2-16位用户名'));
  }
  callback();
}

export function checkPhone(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入手机号码'));
  } else if (!validateMobile(value)) {
    callback(new Error('手机号格式有误'));
  }
  callback();
}

export function checkIdCard(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入身份证号码'));
  } else if (!isIDCard(value)) {
    callback(new Error('身份证格式有误'));
  }
  callback();
}

export function selectEmpty(rule, value, callback) {
  if (!value) {
    callback(new Error('请选择'));
  }
  if (value.length === 0) {
    callback(new Error('选项不能为空'));
  }
  callback();
}

export function checkNull(rule, value, callback) {
  if (!value || value.trim() === '') {
    callback(new Error('请选择'));
  }
  callback();
}

export function less100Charts(rule, value, callback) {
  if (value) {
    if (value.length > 100) {
      callback(new Error('输入内容不超过100字'));
    }
  }
  callback();
}

export function checkUserTag(rule, value, callback) {
  if (value === '') {
    callback(new Error('标签不能为空'));
  } else if (!isUserTag(value)) {
    callback(new Error('请输入1-50字标签'));
  }
  callback();
}

export function less30ChartsNotNull(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入内容'));
  } else if (value.length > 30) {
    callback(new Error('输入内容不超过30字'));
  }
  callback();
}

export function less20ChartsNotNull(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入内容'));
  } else if (value.length > 20) {
    callback(new Error('输入内容不超过20字'));
  }
  callback();
}

export function checkPassword(rule, value, callback) {
  if (value === '') {
    callback(new Error('密码不能为空'));
  } else if (!isPassword(value)) {
    callback(new Error('请输入6-16位密码'));
  }
  callback();
}

export function checkZero(rule, value, callback) {
  if (value <= 0) {
    callback(new Error('请输入非零数值'));
  }
  callback();
}

function isNullOrEmpty(val) {
  if (!val) {
    return true;
  }
  return val.trim() === '';
}
/* 判断模板名称 */
export function checkTemplateName(rule, value, callback) {
  if (isNullOrEmpty(value)) {
    callback(new Error('请输入模板名'));
  }
  if (!/^.{2,50}$/.test(value)) {
    callback(new Error('请输入2-50字模板名'));
  }
  callback();
}

export function viewOauth(permission, buttons) {
  buttons = [
    'project_management_manage',
    'device_monitor_manage',
    'ygs_project_view',
    'ygs_iot_manage',
    'print_monitor_view',
    'fps_manage',
    'dispatch_view',
    'team_view',
    'abnormal_call_view',
    'repair_view',
    'check_operate',
    'maintain_operate',
    'repair_manage',
    'summary_manage',
    'abnormal_call_manage',
    'project_management_view',
    'tech_support_view',
    'abnormal_call_operate',
    'tech_support_manage',
    'maintain_view_all',
    'device_monitor_view',
    'summary_view',
    'check_view',
    'maintain_view',
    'ygs_iot_view',
    'repair_view_all',
    'repair_operate',
    'fountain_monitor_view',
    'ygs_project_manage',
    'plate_monitor_view',
    'device_operate',
    'abnormal_call_view_all',
    'device_view',
    'dispatch_manage',
    'fps_view',
    'check_view_all',
    'team_operate',
    'achievement_view',
  ]
  return buttons.indexOf(permission) !== -1;
}

export function manageOauth(permission, buttons) {
  buttons = [
    'project_management_manage',
    'device_monitor_manage',
    'ygs_project_view',
    'ygs_iot_manage',
    'print_monitor_view',
    'fps_manage',
    'dispatch_view',
    'team_view',
    'abnormal_call_view',
    'repair_view',
    'check_operate',
    'maintain_operate',
    'repair_manage',
    'summary_manage',
    'abnormal_call_manage',
    'project_management_view',
    'tech_support_view',
    'abnormal_call_operate',
    'tech_support_manage',
    'maintain_view_all',
    'device_monitor_view',
    'summary_view',
    'check_view',
    'maintain_view',
    'ygs_iot_view',
    'repair_view_all',
    'repair_operate',
    'fountain_monitor_view',
    'ygs_project_manage',
    'plate_monitor_view',
    'device_operate',
    'abnormal_call_view_all',
    'device_view',
    'dispatch_manage',
    'fps_view',
    'check_view_all',
    'team_operate',
    'achievement_view',
  ]
  return buttons.indexOf(permission) !== -1;
}
