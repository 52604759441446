<template>
  <div>
    <div v-if="locale === 'zh'">
      <h2>合作方SDK清单</h2>
      <table>
        <thead>
        <tr>
          <th>SDK名称</th>
          <th>SDK厂商</th>
          <th>合作目的</th>
          <th>收集个人信息字段及用途</th>
          <th>SDK隐私政策链接</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>华为推送SDK</td>
          <td>华为技术有限公司</td>
          <td>推送</td>
          <td>
            安卓端处理的信息：<br>
            - 个人信息：网络状态、WiFi状态、设备信息（应用程序版本、SDK版本）、消息发送结果、通知栏状态、锁屏状态<br>
            - 权限：网络权限、网络信息状态、WiFi状态、手机状态、存储空间、未知应用安装权限、重新排序正在运行的应用程序
          </td>
          <td><a href="https://developer.huawei.com/consumer/cn/doc/start/20201">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>小米推送SDK</td>
          <td>小米科技有限公司</td>
          <td>推送</td>
          <td>
            安卓端处理的信息：<br>
            - 个人信息：网络状态、WiFi状态、设备信息（应用程序版本、SDK版本、Android ID）、消息发送结果、通知栏状态、锁屏状态<br>
            - 权限：网络权限、网络信息状态、WiFi状态、手机状态、存储空间、未知应用安装权限、重新排序正在运行的应用程序、传感器信息
          </td>
          <td><a href="https://dev.mi.com/console/doc/detail?pId=1822">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>oppo推送SDK</td>
          <td>广东欧珀移动通信有限公司</td>
          <td>推送</td>
          <td>
            安卓端处理的信息：<br>
            - 个人信息：网络状态、WiFi状态、设备信息（应用程序版本、SDK版本）、消息发送结果、通知栏状态、锁屏状态<br>
            - 权限：网络权限、网络信息状态、WiFi状态、手机状态、存储空间、未知应用安装权限、重新排序正在运行的应用程序
          </td>
          <td><a href="https://open.oppomobile.com/wiki/doc#id=10194">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>vivo推送SDK</td>
          <td>广东天宸网络科技有限公司</td>
          <td>推送</td>
          <td>
            安卓端处理的信息：<br>
            - 个人信息：网络状态、WiFi状态、设备信息（应用程序版本、SDK版本）、消息发送结果、通知栏状态、锁屏状态<br>
            - 权限：网络权限、网络信息状态、WiFi状态、手机状态、存储空间、未知应用安装权限、重新排序正在运行的应用程序
          </td>
          <td><a href="https://dev.vivo.com.cn/wap/documentCenter/doc/366">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>荣耀推送SDK</td>
          <td>荣耀终端有限公司</td>
          <td>推送</td>
          <td>
            安卓端处理的信息：<br>
            - 个人信息：网络状态、WiFi状态、设备信息（应用程序版本、SDK版本）、消息发送结果、通知栏状态、锁屏状态<br>
            - 权限：网络权限、网络信息状态、WiFi状态、手机状态、存储空间、未知应用安装权限、重新排序正在运行的应用程序
          </td>
          <td><a href="https://developer.hihonor.com/cn/kitdoc?category=%E5%9F%BA%E7%A1%80%E6%9C%8D%E5%8A%A1&amp;kitId=11002&amp;navigation=guides&amp;docId=agreement.md&amp;token=">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>个推消息推送SDK</td>
          <td>每日互动股份有限公司</td>
          <td>为App用户提供消息推送技术服务</td>
          <td>
            安卓端处理的信息：<br>
            （1）设备信息、网络信息：必选信息：设备平台、设备厂商、设备品牌、设备型号及系统版本、OAID、AndroidID、WIFI连接信息、运营商信息、DHCP、SSID、BSSID<br>
            可选信息：IMEI、MAC、Google Advertising ID（GAID）、Serial Number、IMSI、ICCID、IP地址、基站信息。<br>
            （2）位置相关信息：用于应景推送、智能推送和应急推送扩展功能。<br>
            （3）应用列表信息：用于智能推送、应急推送和链路调节器扩展功能。

            iOS端、HarmonyOS端处理的信息：<br>
            （1）设备信息：设备识别码等用于生成唯一的推送目标ID（CID）和设备ID（GID）。<br>
            （2）网络信息以及位置相关信息：用于保持网络连接的稳定性，提供应景推送功能。
          </td>
          <td><a href="https://docs.getui.com/privacy">查看隐私政策</a></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h2>Partner SDK List</h2>
      <table>
        <thead>
        <tr>
          <th>SDK Name</th>
          <th>SDK Vendor</th>
          <th>Purpose of Cooperation</th>
          <th>Personal Information Collected and Its Purpose</th>
          <th>SDK Privacy Policy Link</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Huawei Push SDK</td>
          <td>Huawei Technologies Co., Ltd.</td>
          <td>Push Notifications</td>
          <td>
            Information on Android:：<br>
            - Personal Information: Network status, WiFi status, device information (app version, SDK version), message delivery results, notification bar status, lock screen status<br>
            - Permissions: Network access, network status, WiFi status, phone status, storage space, permission to install unknown apps, reorder running apps
          </td>
          <td><a href="https://developer.huawei.com/consumer/cn/doc/start/20201">Click to view the third-party privacy policy</a></td>
        </tr>
        <tr>
          <td>Xiaomi Push SDK</td>
          <td>Xiaomi Corporation</td>
          <td>Push Notifications</td>
          <td>
            Information on Android:<br>
            - Personal Information: Network status, WiFi status, device information (app version, SDK version, Android ID), message delivery results, notification bar status, lock screen status<br>
            - Permissions:Network access, network status, WiFi status, phone status, storage space, permission to install unknown apps, reorder running apps, sensor information
          </td>
          <td><a href="https://dev.mi.com/console/doc/detail?pId=1822">Click to view the third-party privacy policy</a></td>
        </tr>
        <tr>
          <td>OppO Push SDK</td>
          <td>OPPO Guangdong Mobile Communications Co., Ltd</td>
          <td>Push Notifications</td>
          <td>
            Information on Android:<br>
            - Personal Information: Network status, WiFi status, device information (app version, SDK version), message delivery results, notification bar status, lock screen status<br>
            - Permissions: Network access, network status, WiFi status, phone status, storage space, permission to install unknown apps, reorder running apps
          </td>
          <td><a href="https://open.oppomobile.com/wiki/doc#id=10194">Click to view the third-party privacy policy</a></td>
        </tr>
        <tr>
          <td>Vivo Push SDK</td>
          <td>Guangdong Tianchen Network Technology Co., Ltd</td>
          <td>Push Notifications</td>
          <td>
            Information on Android:<br>
            - Personal Information: Network status, WiFi status, device information (app version, SDK version), message delivery results, notification bar status, lock screen status<br>
            - Permissions: Network access, network status, WiFi status, phone status, storage space, permission to install unknown apps, reorder running apps
          </td>
          <td><a href="https://dev.vivo.com.cn/wap/documentCenter/doc/366">Click to view the third-party privacy policy</a></td>
        </tr>
        <tr>
          <td>Honor Push SDK</td>
          <td>Honor Device Co., Ltd</td>
          <td>Push Notifications</td>
          <td>
            Information on Android:<br>
            - Personal Information: Network status, WiFi status, device information (app version, SDK version), message delivery results, notification bar status, lock screen status<br>
            - Permissions: Network access, network status, WiFi status, phone status, storage space, permission to install unknown apps, reorder running apps
          </td>
          <td><a href="https://developer.hihonor.com/cn/kitdoc?category=%E5%9F%BA%E7%A1%80%E6%9C%8D%E5%8A%A1&amp;kitId=11002&amp;navigation=guides&amp;docId=agreement.md&amp;token=">点击查看第三方隐私政策</a></td>
        </tr>
        <tr>
          <td>Getui Message Push SDK</td>
          <td>Zhejiang Merit Interactive Network Technology Co.,Ltd</td>
          <td>provide message push technology services for app users</td>
          <td>
            Information on Android:<br>
            （1）(1)Device Information, Network Information: Device platform, device manufacturer, device brand, device model and system version, OAID, Android ID, WIFI connection information, carrier information, DHCP, SSID, BSSID<br>
            Optional: IMEI, MAC, Google Advertising ID (GAID), Serial Number, IMSI, ICCID, IP address, base station information.<br>
            (2)Location-related Information: Used for occasion-based pushes, smart pushes, and emergency push extension features.<br>
            (3)Application List Information: Used for smart pushes, emergency pushes, and link modulator extension features.

            iOS and HarmonyOS information processing：<br>
            (1)Device Information: Device identifiers for generating unique push target ID (CID) and device ID (GID).<br>
            (2)Network Information and Location-related Information: To maintain network connection stability and provide occasion-based push features.
          </td>
          <td><a href="https://docs.getui.com/privacy">View privacy policy</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
export default {
  name: "partners.vue",
  data () {
    return {
      locale:localStorage.getItem('locale') || 'zh'
    }
  },
  async created () {
    window.goBack = this.onClickLeft
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
h2{
  text-align: center;
  margin-top: 15px;
}
::v-deep .van-nav-bar {
  background-color: #212C64;
}

::v-deep .van-nav-bar .van-icon {
  color: white;
}

::v-deep .van-nav-bar__title {
  color: white;
  margin: 0 auto;
  font-size: 17px;
}

::v-deep .van-nav-bar__content {
  background-color: #212c64;
  position: fixed;
  top: 0;
  width: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin-top: 25px;
}
th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
</style>
