<template>
  <div class="wrapper">
    <div class="container">
      <!--页头-->
      <div class="page-header" style="line-height: 40px">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">Privacy Policy</h2>
        </div>
      </div>
      <p>Update Date: September 25, 2024</p>
      <p>Effective Date: January 1, 2020</p>
      <br>
      <!--内容-->
      <div class="agreement-content">
        <ul>
          <li>
            Printer Cybertech (Qingdao) Digital Technology Co., Ltd. (hereinafter referred to as "Printer Cybertech" or "we") is fully aware of the importance of your privacy and fully respects it. Please carefully read and understand this "Privacy Policy Statement" (hereinafter referred to as "this statement") before agreeing to submit your personal data to us. We will use and disclose your personal information in accordance with the provisions of this privacy policy. Except as otherwise stipulated in this privacy policy, we will not disclose or provide this information to third parties without obtaining your prior consent. By agreeing to this app service use agreement, it is deemed that you have agreed to the entire content of this privacy policy. This privacy policy is an inseparable part of the app service use agreement.
          </li>

          <li>
            <h3>1.Scope of Application</h3>
          </li>

          <li>
            <p>
              (a) Personal registration information you provide according to the app's requirements when you register for an app account;
            </p>

            <p>
              (b) Information automatically received and recorded by the app from your browser and computer when you use the app’s network services or visit the app platform pages, including but not limited to your IP address, browser type, language used, access date and time, software and hardware characteristic information, and web page records you require, etc.;
            </p>

            <p>
              (c) User personal data obtained by the app through legal means from business partners.
            </p>

            <p>
              You understand and agree that the following information is not applicable to this privacy policy:
            </p>

            <p>
              (a) Keyword information you enter when using the search services provided by the app platform;
            </p>

            <p>
              (b) Information data collected by the app about you, including but not limited to participation in activities, transaction information, and evaluation details published on the app;
            </p>

            <p>
              (c) Violations of legal provisions or the app rules and the measures the app has taken against you.
            </p>
          </li>

          <li>
            <h3>2.Information We Collect</h3>
          </li>

          <li>
            <p>
              We will explain the types of your personal information we collect and their corresponding uses one by one so that you understand the specific categories of personal information collected for a particular function, the reasons for use, and the collection method.
            </p>
            <p>
              When you use certain features, we will collect some of your sensitive information after obtaining your consent. Currently, we will not actively share or transfer your personal information to a third party. You can access, correct, and delete your personal information through the paths listed in this guide.
            </p>
            <p>
              To collect your information under this guide, or to provide services, optimize our services, and ensure the security of your account, we will need to request relevant permissions from you; sensitive permissions such as camera, microphone, and album are not enabled by default and will only be authorized by you with your explicit consent.
            </p>
            <p>
              It needs to be particularly noted that obtaining sensitive permissions is a necessary but not sufficient condition for us to collect specific information. The acquisition of specific sensitive permissions does not necessarily mean that we will collect your relevant information; even if we have obtained sensitive permissions, we will only collect your relevant information when necessary, and according to this guide.
            </p>
            <p>
              The functions and services we provide to you are continuously being updated and developed. If a certain function or service is not mentioned in the above description but collects your information, we will separately explain the content, scope, and purpose of information collection to you through page prompts, interaction processes, website announcements, etc., to obtain your consent.
            </p>
            <!--                  <p>-->
            <!--                    请你注意,你使用服务过程中有可能收集你的设备相关信息，例如IMEI/OAID、GAID编号、IMSI编号、MAC地址、序列号、系统版本及类型、ROM版本、Android版本、Android ID。-->
            <!--                  </p>-->
            <!--                  <p>-->
            <!--                    请你注意,你使用服务过程中有可能个推消息推送SDK，其目的为App用户提供消息推送技术服务。-->
            <!--                  </p>-->
            <!--                  <p>-->
            <!--                    本应用使用个推推送 SDK 提供推送服务，该 SDK 会收集以下信息：<br> 设备信息：SSID（无线网络名称）、BSSID（无线网络 MAC 地址）、GAID（Google 广告 ID）。-->
            <!--                  </p>-->
            <p>
              Information processed on the Android side:
            </p>
            <p>
              (a) Device Information, Network Information: Mandatory Information includes device platform, device manufacturer, device brand, device model and system version, OAID, AndroidID, WIFI connection information, carrier information. Optional Information includes IMEI, MAC, Advertising ID, Serial Number, IMSI, ICCID, IP address, base station information. This data is used to generate a unique push target ID (CID) and device ID (GID) to ensure accurate message delivery and precise device identification.
            </p>
            <p>
              (b) Location-Related Information: Mandatory information for emergency push extension functions. As required by national authorities, and to enhance mobile internet services for social welfare, APP message pushes play a role in disaster warnings. When major disasters such as earthquakes and meteorological events occur, relevant disaster notification information is delivered in real-time to affected users through the developer's APP push channel.
            </p>
            <p>
              (c) Application List Information: Mandatory information for emergency push and link integrator extension functions. Required by national authorities to improve mobile internet services for social welfare, APP message pushes play a role in disaster warnings. Upon the occurrence of major disasters such as earthquakes and meteorological events, disaster notification messages are delivered in real-time to affected users via the developer's APP push channel. We employ link merging technology for technical pushing. When a device has multiple APP push links active simultaneously, we randomly merge them into a single link to save power and data usage. When offline push messages need to be sent on a device, the associated launch function can deliver offline messages. You can choose to enable or disable this service; disabling this service also closes the associated launch, potentially increasing power and data consumption while reducing message reach rates.
            </p>
            <p>
              iOS-specific Information:
            </p>
            <p>
              (a) Device Information: Device identifiers (IDFA, IDFV), device platform, device manufacturer for generating unique push target ID (CID) and device ID (GID), ensuring accurate message delivery and device identification. Device brand, model, and system version are used to optimize channel resource effectiveness, improving message delivery rate and stability.(2) Network Information (including IP address, WIFI information, base station information, carrier information) and location-related information: We need to understand the device's network status and changes to maintain a stable continuous push service and long connections. To ensure the stable operation and functionality of our push services, allowing you to use and enjoy more features and services, our services incorporate SDKs and APIs provided by partners. We conduct strict security checks on the information obtained through SDKs and APIs and agree on stringent data protection measures with partners to handle personal information in accordance with this policy and other relevant confidentiality and security measures.
            </p>
            <p>
              Purpose: To provide users with emergency push and other extended features.
              <!--                    SSID 和 BSSID: 优化推送消息的精准度，提高用户体验。GAID: 为您提供更精准的个性化推送内容。-->
            </p>
            <!--                  <p>范围：个推推送 SDK 仅在您使用本应用推送功能时才会收集上述信息。</p>-->
            <p>
              <a href="#/partners">View partner privacy policies</a>

            </p>
            <!--                  <p>-->
            <!--                    您可以查看个推推送 SDK 的官方隐私政策，了解更多关于信息收集和使用的细节： https://www.getui.com/privacy-->
            <!--                  </p>-->
            <!--                  <p>-->
            <!--                    请你注意，目前我们不会主动从第三方获取你的个人信息。如未来为业务发展需要从第三方间接获取你个人信息，我们会在获取前向你明示你个人信息的来源、类型及使用范围；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。-->
            <!--                  </p>-->
          </li>

          <li>
            <h3>3.Use of Information</h3>
          </li>

          <li>
            <p>
              (a) This application will not provide, sell, rent, share, or trade your personal information to any unrelated third parties without your prior consent, unless such third parties and this application (including affiliated companies of this application) individually or jointly provide services to you and are prohibited from accessing all such previously accessible information after the service is completed.
            </p>

            <p>
              (b) This application also does not allow any third party to collect, edit, sell, or distribute your personal information by any means. Any user of this platform engaging in such activities will have their service agreement terminated immediately once discovered.
            </p>

            <!--                  <p>-->
            <!--                    (c) 为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，-->
            <!--                    或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。-->
            <!--                  </p>-->
          </li>

          <li>
            <h3>4.Disclosure of Information</h3>
          </li>

          <li>
            <p>
              In the following circumstances, this application will disclose your personal information in whole or part according to your personal wishes or legal provisions:
            </p>

            <p>
              (a) With your prior consent, disclose to third parties;
            </p>

            <p>
              (b) To provide the products and services you requested, it is necessary to share your personal information with third parties;
            </p>

            <p>
              (c) According to relevant laws, or the request of administrative or judicial institutions, disclose to third parties or administrative or judicial institutions;
            </p>

            <p>
              (d) If you violate China's relevant laws, regulations, or the service agreement or related rules of this application, disclosure to third parties is necessary;
            </p>

            <p>
              (e) If you are a qualified intellectual property complainant and have lodged a complaint, upon the request of the respondent, disclose to the respondent to handle potential rights disputes;
            </p>

            <p>
              (f) In a transaction created on this application platform, if any party fulfills or partially fulfills the transaction obligations and requests information disclosure, this application has the right to decide to provide the user's contact information or other necessary information to the transaction party to complete the transaction or resolve disputes;
            </p>

            <p>
              (g) Other disclosures deemed appropriate by this application according to laws, regulations, or site policies.
            </p>
          </li>

          <li>
            <h3>5.Information Storage and Exchange</h3>
          </li>

          <li>
            The information and data collected about you will be stored on the servers of this application and/or its affiliated companies. This information and data may be transmitted to your country, region, or the place where this application collects information and data, and be accessed, stored, and displayed overseas.
          </li>

          <li>
            <h3>6.Use of Cookies</h3>
          </li>

          <li>
            <p>
              (a) If you do not refuse to accept cookies, this application will set or access cookies on your computer so that you can log in or use services or functions relying on cookies of this platform.
            </p>

            <p>
              (b) You have the right to choose to accept or refuse cookies. You can refuse cookies by modifying your browser settings, but doing so may prevent you from logging in or using the network services or functions relying on cookies of this application.
            </p>

            <p>
              (c) Information obtained through cookies set by this application will be applicable to this policy.
            </p>
          </li>

          <li>
            <h3>7.Information Security</h3>
          </li>

          <li>
            <p>
              (a) Accounts of this application have security protection features. Please keep your username and password information secure. This application will take security measures such as encrypting user passwords to ensure your information is not lost, misused, or altered. Despite such security measures, please be aware that there are no "perfect security measures" on information networks.
            </p>

            <!--<p>
                (b) 在使用本应用网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对
              </p>-->
          </li>

          <li>
            <h3>8.Changes to This Privacy Policy</h3>
          </li>

          <li>
            <p>
              (a) If we decide to change the privacy policy, we will post these changes in this policy, on our company's website, and where we deem appropriate, so that you can understand how we collect and use your personal information, who can access this information, and under what circumstances we disclose it.
            </p>

            <p>
              (b) Our company reserves the right to modify this policy at any time, so please review it frequently. If any significant changes are made, we will inform you via a notification on the website.
            </p>

            <p>
              To prevent disclosure of your personal information, such as contact details or postal address, please protect your personal information carefully and provide it to others only when necessary. If you find your personal information leaked, especially the username and password of this application, please contact our customer service immediately so that we can take corresponding measures.
            </p>
          </li>
          <li>
            <h3>9.How to Contact Us</h3>
          </li>
          <li>
            <p>
              If you have any questions about this privacy policy, need further information, or wish to know how your personal information is used, accessed, corrected, or deleted, or have other privacy-related inquiries, you can contact us at: Email:ygs@cpct.net.cn.
            </p>
            <p>
              Please provide sufficient information when contacting us, so that we can address your inquiry, including your name, contact details, and specific issues or requests regarding our services. Also, if you believe our handling is not in compliance with relevant privacy protection regulations, you have the right to lodge a complaint with the relevant data protection agency. Please note that to protect your privacy and security, we may need to verify your identity when processing your request.
            </p>
            <p>
              Furthermore, if you believe that our handling does not comply with relevant privacy protection regulations, you have the right to lodge a complaint with the appropriate data protection authority. Please note that to protect your privacy and security, we may need to verify your identity when processing your request.
            </p>
          </li>
          <!--                <li>-->
          <!--                  <h3>9.个推用户隐私政策</h3>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <p>-->
          <!--                    (a)消息推送SDK服务:为开发者赋予消息和通知的推送能力,为满足使用者不同维度的服务要求，我们提供智能推送、应急推送等扩展功能供使用者选择使用。-->
          <!--                  </p>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <p>-->
          <!--                    (b)处理的信息:推送目标ID（CID）和设备ID（GID），以此保证消息推送的准确下发和消息设备的准确识别。-->
          <!--                  </p>-->
          <!--                </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage',
  data() {
    return {};
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log(to)
  //   // 改变网页、面包屑标题内容
  //   next(vm => {
  //     to.meta.title = '隐私政策'
  //   })
  // },
  created() {
    // console.log(this.$route);
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

.container {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.agreement-content {
  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;
    }
  }

  h3,
  h4,
  p {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
